import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import axios from 'axios'
import adminRoutes from '@/gestor/admin/router'

const Login = () => import('../views/Login.vue')
const Estadisticas = () => import('../views/Estadisticas.vue')
const EstadisticasInternal = () => import('../views/EstadisticasInternal.vue')
const ProgramasPublicos = () => import('../views/ProgramasPublicos.vue')
const CicloAprendizaje = () => import('../views/CicloAprendizaje.vue')
const Encuestas = () => import('../views/Encuestas.vue')
const Instrumentos = () => import('../views/Instrumentos.vue')
const DatosAbiertos = () => import('../views/DatosAbiertos.vue')
const DatosAbiertosInternal = () => import('../views/DatosAbiertosInternal.vue')
const Genero = () => import('../views/Genero.vue')
const DocumentosGenero = () => import('../views/DocumentosGenero.vue')
const IndicadoresGenero = () => import('../views/IndicadoresGenero.vue')
const GeneroInternal = () => import('../views/GeneroInternal.vue')
const Sistema = () => import('../views/Sistema.vue')
const EncuestasInternal = () => import('../views/EncuestasInternal.vue')
const Estudios = () => import('../views/Estudios.vue')
const EstudiosInternal = () => import('../views/EstudiosInternal.vue')
const Glosario = () => import('../views/Glosario.vue')
const BusquedaResultados = () => import('../views/BusquedaResultados.vue')
const LlamadoInvestigacion = () => import('../views/LlamadoInvestigacion.vue')
const Privacidad = () => import('../views/Privacidad.vue')
const ConoceObserva = () => import('../views/ConoceObserva.vue')
const Suscribirse = () => import('../views/Suscribirse.vue')
const BuscadorProyectos = () => import('../views/BuscadorDeProyectos.vue')
const DirectorioEBCT = () => import('../views/DirectorioEBCT.vue')
const MEvaluaciones = () => import('../views/mapa-evaluaciones.vue')
Vue.use(VueRouter)

const baseRoutes = [

    { path: '/', name: 'Home', component: Home, meta: { noAuth: true } },
    { path: '/login', name: 'Login', component: Login, meta: { noAuth: true } },
    { path: '/indicadores', name: 'Estadisticas', component: Estadisticas, meta: { noAuth: true, title: 'Indicadores' } },
    // { path: '/indicadoresInternal/:categoria', name: 'EstadisticasInternalCategoria', component: EstadisticasInternal, meta: { noAuth: true, title: 'Indicadores' } },
    { path: '/indicadores/:categoria', name: 'EstadisticasCategoria', component: EstadisticasInternal, meta: { noAuth: true, title: 'Indicadores' } },
    { path: '/indicadores/:categoria/:pagina', name: 'EstadisticasPagina', component: EstadisticasInternal, meta: { noAuth: true, title: 'Indicadores' } },
    { path: '/programas-publicos/instrumentos', name: 'Instrumentos', component: Instrumentos, meta: { noAuth: true, title: 'Instrumentos' } },
    { path: '/programas-publicos/ciclo-de-aprendizaje', name: 'CicloAprendizaje', component: CicloAprendizaje, meta: { noAuth: true, title: 'Ciclo de aprendizaje' } },
    { path: '/encuestas/directorio-ebct', name: 'DirectorioEBCT', component: DirectorioEBCT, meta: { noAuth: true, title: 'Directorio EBCT' } },
    { path: '/programas-publicos/mapa-evaluaciones', name: 'MEvaluaciones', component: MEvaluaciones, meta: { noAuth: true, title: 'Mapa de Evaluaciones' } },
    { path: '/programas-publicos/buscador-proyectos', name: 'BuscadorProyectos', component: BuscadorProyectos, meta: { noAuth: true, title: 'Buscador de Proyectos' } },
    { path: '/programas-publicos', name: 'ProgramasPublicos', component: ProgramasPublicos, meta: { noAuth: true, title: 'Programas Públicos' } },
    { path: '/encuestas', name: 'Encuestas', component: Encuestas, meta: { noAuth: true, title: 'Encuestas' } },
    { path: '/datos-abiertos', name: 'DatosAbiertos', component: DatosAbiertos, meta: { noAuth: true, title: 'Datos Abiertos' } },
    { path: '/datos-abiertos/:id', name: 'DatosAbiertosInternal', component: DatosAbiertosInternal, meta: { noAuth: true, title: 'Datos Abiertos' } },
    { path: '/estudios/:id', name: 'EstudiosInternal', component: EstudiosInternal, meta: { noAuth: true, title: 'Estudios' } },
    { path: '/genero-home', name: 'Genero', component: Genero, meta: { noAuth: true, title: 'Indicadores Género' } },
    { path: '/genero/documentos', name: 'DocumentosGenero', component: DocumentosGenero, meta: { noAuth: true, title: 'Indicadores Género' } },
    { path: '/genero/indicadores', name: 'IndicadoresGenero', component: IndicadoresGenero, meta: { noAuth: true, title: 'Indicadores Género' } },
    { path: '/genero/:categoria', name: 'GeneroInternalCategoria', component: GeneroInternal, meta: { noAuth: true, title: 'Indicadores Género' } },
    { path: '/genero/:categoria/:pagina', name: 'GeneroPagina', component: GeneroInternal, meta: { noAuth: true, title: 'Indicadores Género' } },
    { path: '/sistema', name: 'Sistema', component: Sistema, meta: { noAuth: true, title: 'Sistema' } },
    { path: '/encuesta/:titulo', name: 'EncuestasInternal', component: EncuestasInternal, meta: { noAuth: true, title: 'Encuesta' } },
    { path: '/encuestas/:id', name: 'DatosAbiertosInternal', component: DatosAbiertosInternal, meta: { noAuth: true, title: 'Encuesta' } },
    { path: '/estudios-y-publicaciones', name: 'Estudios', component: Estudios, meta: { noAuth: true, title: 'Estudios y Publicaciones' } },
    { path: '/glosario', name: 'Glosario', component: Glosario, meta: { noAuth: true, title: 'Glosario' } },
    { path: '/busqueda-resultados', name: 'BusquedaResultados', component: BusquedaResultados, meta: { noAuth: true }, props: true },
    { path: '/contactanos', name: 'Contacto', component: () => import('@/views/Contacto.vue'), meta: { noAuth: true, title: 'Contáctanos' } },
    { path: '/llamado-investigacion', name: 'LlamadoInvestigacion', component: LlamadoInvestigacion, meta: { noAuth: true, title: 'Llamado Investiación' } },
    { path: '/terminos-legales', name: 'Privacidad', component: Privacidad, meta: { noAuth: true, title: 'Términos Legales' } },
    { path: '/conoce-observa', name: 'ConoceObserva', component: ConoceObserva, meta: { noAuth: true, title: 'Conoce Observa' } },
    { path: '/suscribirse', name: 'Suscribirse', component: Suscribirse, meta: { noAuth: true, title: 'Suscribirse' } },
]

const catchAll = [
    { path: '/:nombre', name: 'Pagina', component: () => import('@/views/Pagina.vue'), meta: { noAuth: true } },
    { path: '/:catchAll(.*)', name: 'Error404', component: () => import('../views/404.vue'), meta: { requiresAuth: false } },
    { path: '/404', name: 'Error404', component: () => import('../views/404.vue'), meta: { requiresAuth: false } }
]

const routes = baseRoutes.concat(adminRoutes).concat(catchAll)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: function (to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        } else {
            return { x: 0, y: 0 }
        }
    }

})

router.beforeEach(function (to, from, next) {
    if (to.fullPath === '/login/?logout') {
        localStorage.removeItem('jwt.access')
        localStorage.removeItem('jwt.refresh')
        localStorage.removeItem('current_user_info')
        next({ path: '/login' })
        return
    }

    if (!(typeof comment === 'undefined') && !to.meta.noAuth) {
        if (localStorage.getItem('jwt.access') === null) {
            next({ path: '/login', params: { nextUrl: to.fullPath } })
            return
        }
    }

    // Validar las rutas de administración
    if (to.meta?.noAuth === false && (!localStorage.getItem('jwt.access') || localStorage.getItem('jwt.access') === '')) {
        return router.push({
            path: '/login',
            query: {
                redirect: to.fullPath
            }
        })
        // next({ path: '/login', params: { nextUrl: to.fullPath } })
    }
    next()
})

function getJWTExpireDate (jwtToken) {
    if (jwtToken) {
        try {
            const [, payload] = jwtToken.split('.')
            const { exp: expires } = JSON.parse(window.atob(payload))
            if (typeof expires === 'number') {
                return new Date(expires * 1000)
            }
        } catch {
        }
    }
    return null
}

var contador = 0

function refreshToken (url) {
    const refreshToken = localStorage.getItem('jwt.refresh')
    const token = localStorage.getItem('jwt.access')
    if (refreshToken && url.search('/api/content/refresh/') < 0) {
        if ((getJWTExpireDate(token) - (new Date())) / 1000 < 50) {
            const refreshToken = localStorage.getItem('jwt.refresh')
            if (!refreshToken) {
                localStorage.removeItem('jwt.access')
                localStorage.removeItem('jwt.refresh')
            }
            contador += 1

            if (contador > 50) {
                localStorage.removeItem('jwt.access')
                localStorage.removeItem('jwt.refresh')
                contador = 0
            }
            axios.post(process.env.VUE_APP_BASEURL + '/api/refresh/', { refresh: refreshToken })
                .then(function (res) {
                    if (res.status === 200) {
                        contador = 0
                        localStorage.setItem('jwt.access', res.data.access)
                    }
                }).catch(function () {
                    localStorage.removeItem('jwt.access')
                    localStorage.removeItem('jwt.refresh')
                    router.push({ name: 'Login', query: { t: new Date().getTime() } })
                })
        }
    }
}
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('jwt.access')
        refreshToken(config.url)

        if (config.url.search('http') !== 0 && config.url.search('/api/') === 0) {
            config.url = process.env.VUE_APP_BASEURL + config.url
        }
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },

    (error) => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use((response) => {
    return response
}, function (error) {
    // const originalRequest = error.config

    if (error.response && error.response.status === 401 && error.response.data && (error.response.data.detail === 'Authentication credentials were not provided.' || error.response.data.detail === 'Las credenciales de autenticación no se proveyeron.')) {
        localStorage.removeItem('jwt.access')
        localStorage.removeItem('jwt.refresh')
        router.push('/login')
        return Promise.reject(error)
    }

    if (error.response && error.response.status === 401 && error.response.data && error.response.data.code === 'token_not_valid') {
        // si el url es el refresh, signifia que ya es el segundo request una vez que el primero no fue existos o por tanto debe devolver el error
        if (error.config.url.search('/api/token/refresh/') >= 0) {
            localStorage.removeItem('jwt.access')
            router.push('/login')
            return Promise.reject(error)
        }

        // caso especial para desarrollo local, en caso de tener varios proyectos desarrollando
        const token = localStorage.getItem('jwt.access')
        if (getJWTExpireDate(token) - (new Date()) / 1000 > 500) {
            localStorage.removeItem('jwt.access')
        }
    }

    return Promise.reject(error)
})

export default router
